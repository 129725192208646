import React, { useEffect, useState } from 'react'
import {Alert, Container, Row} from 'reactstrap'
import {useParams} from 'react-router-dom'
import {connect} from 'react-redux'
import {getApiByFriendlyName} from '../../redux/actions/apis'
import {clearPlanAlert, getPlanByApiAndOrganization} from '../../redux/actions/plans'

import TierCardContainer from './components/TierCardContainer'
import BillingInformation from '../Apis/components/BillingInformation'
import CurrentBillingCycle from '../Apis/components/CurrentBilling/CurrentBillingCycle'
import InvoicesTable from '../Apis/components/InvoicesTable'

import FreeTierModal from '../Apis/components/FreeTierModal'
import StripeSignupOrSwitchModal from '../Apis/components/StripeSignupOrSwitchModal'
import { getUserInvoices, getUserLatestStripeSubscription } from '../../redux/actions/users';

const PlansAndPrices = (props) => {

  const [ tiers, setTiers ] = useState([])

    const planAlert = props.planAlert || {};
    const userAlert = props.userAlert || {};

    let {name} = useParams()


    const getApi = () => {
        if(!props.api || props.api.uriFriendlyName !== name){
            if(!props.apiError){
                props.getApiByFriendlyName(name);
            }
        }
    }

    const getPlan = () =>{
        if(props.user && props.api.id && !props.plan.id && !props.planError ){
            props.getPlanByApiAndOrganization({organizationId: props.user.defaultOrgId});
        }
    }

    const totalApiCreditsUsed =  props.currentBillingCycle.totalCalls
    const totalApiCreditsAllowed = () => {
      const currentTier = props.api && props.api.tiers && props.api.tiers.find((t) => t.id === props.plan.tierId) || {};
      //We need to account for free plans

      //Free plans do not have a pricing scheme
      //If the currentTier doesn't have a pricing scheme we need to use the rateLimits property
      if(currentTier && currentTier.pricingScheme) {
        return currentTier.pricingScheme.steps && currentTier.pricingScheme.steps[0] && currentTier.pricingScheme.steps[0].ceiling
      } else {
        return currentTier && currentTier.rateLimits && currentTier.rateLimits[0] && currentTier.rateLimits[0].quantity
      }
    }

    useEffect(() => {
    if(!props.user && !props.userLoading){
      props.history.push('/login')
    }
  }, [!props.userLoading]);

    useEffect(() => {
      const getUserBillingDetails = () => {
        getApi()
        getPlan()
        props.getUserLatestStripeSubscription()
      }

      props.user && props.api ? getUserBillingDetails() : ''
    }, [props.user, props.api] )

    useEffect(() => {
      if( props.api.tiers ) {
        let availableTiers;
        if(props.preferences.view === 'MARKETER') {
          availableTiers = props.api.tiers.filter(tier => tier.isMarketing === true)
          
          const freeTierAndCurrentTier = props.api.tiers.find(tier => tier.priceMonthly === 0 && !tier.isDiscontinued && !tier.isTrial)
          if(freeTierAndCurrentTier) {
            availableTiers.push(freeTierAndCurrentTier)
          }
          
        } else if(props.preferences.view === 'DEVELOPER') {
          availableTiers = props.api.tiers.filter(tier => tier.isMarketing === false)
        }
        setTiers(availableTiers)
      }
      }, [ props.api.tiers, props.preferences ])




    return (
            <Container style={{maxWidth: '1320px'}} className="dashboard">
                <Alert color={planAlert.color} className="alert--bordered" isOpen={!!planAlert.message} toggle={props.clearPlanAlert}>
                    <div className={'padding5'}>
                        <span>{planAlert.message}</span>
                    </div>
                </Alert>
                <StripeSignupOrSwitchModal />
                <FreeTierModal />
              {tiers && props.plan && <TierCardContainer show tiers={tiers} plan={props.plan}/>}
                <Row>
                  <BillingInformation plan={props.plan} currentTierId={props.plan.tierId} source={props.plan.source} planAlert={props.planAlert}/>
                  <CurrentBillingCycle isDeveloper={props.preferences.view === 'DEVELOPER'} currentBillingCycle={props.currentBillingCycle} totalApiCreditsUsed={totalApiCreditsUsed} totalApiCreditsAllowed={totalApiCreditsAllowed()}/>
                </Row>
                <Row>
                    <InvoicesTable />
                </Row>
            </Container>
    )
}

const mapStateToProps = (state) => ({
    api: state.apis.detailApi,
    user: state.users.user,
    currentBillingCycle: state.organization.currentOrg.currentBillingCycle,
    currentOrg: state.organization.currentOrg,
    plan: state.plans.detailPlan,
    userLoading: state.users.loading,
    planAlert: state.plans.alert,
    planError: state.plans.error,
    apiError: state.apis.error,
    userAlert: state.users.alert,
    stripeModalVisible: state.stripeModal.visible,
    freeModalVisible: state.stripeModal.freeModalVisible,
    stripeModalTier: state.stripeModal.tier,
    preferences: state.preferences.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
    getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
    getPlanByApiAndOrganization: (() => dispatch(getPlanByApiAndOrganization())),
    clearPlanAlert: () => dispatch(clearPlanAlert()),
    getUserLatestStripeSubscription: () => dispatch(getUserLatestStripeSubscription()),
    getUserInvoices: () => dispatch(getUserInvoices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlansAndPrices)
