import React, { useEffect, useState} from 'react';
import {  Container } from 'reactstrap';
import LoginModal from '../../shared/components/Modals/LoginModal';
import { connect } from 'react-redux';

import { getApiByFriendlyName } from '../../redux/actions/apis';


import { getPlanByApiAndOrganization } from '../../redux/actions/plans';
import { getImageLayouts, getOrganizationImageDesigns } from '../../redux/actions/linkPreview';

import Tabs from './components/Tabs/Tabs';
import TabContentComponent from './components/Tabs/TabContent';

import './linkPreview.css';

const LinkPreview = (props) => {
  const [ displayLoginModal, setDisplayLoginModal ] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);

  const [ activeTab, setActiveTab ] = useState('dashboard');

  useEffect(() => {
    if(!props.api.id && !props.apiError) {
      props.getApiByFriendlyName('Opengraphio');
    }
  }, [props.api])

  useEffect(() => {
    if (!props.user && !props.userState.loading) {
      setDisplayLoginModal(true);
    }
    if (props.user && props.api.id && !props.plan.id && !props.planError) {
      props.getPlanByApiAndOrganization();
    }

  }, [props.api.id, props.user, props.plan.id, props.planError, props.apiError]);


  useEffect(() => {
    if(props.plan && props.plan.tierId && props.api && props.api.tiers){
      setCurrentPlan(props.api.tiers.find((tier) => tier.id === props.plan.tierId))
    }

  }, [props.plan, props.api])

  useEffect(() => {
    if(props.user) {
      setDisplayLoginModal(false);
    }
  }, [props.user]);

  useEffect(() => {
    if(props.user && props.currentOrganization.information.id && props.plan.id) {
      if(props.imageDesigns.designs.length === 0) {
        props.getOrganizationImageDesigns();
      }
    }
  }, [props.user, props.currentOrganization.information, props.plan.id]);

  useEffect(() => {
    if(props.imageLayouts.layouts.length === 0 && props.plan.id) {
      props.getImageLayouts();
    }
  }, [props.plan.id]);

  useEffect(() => {
    if(props.selectedDesign.design && props.selectedDesign.tabSwitch) {
      setActiveTab('images');
    }
  }, [props.selectedDesign.tabSwitch]);

  return (
    <Container style={{maxWidth: '1320px'}}  className="dashboard">
      {displayLoginModal ? <LoginModal userState={props.userState} visible={displayLoginModal} formData={{ formData: props.formData }}/> : '' }
      {props.user && props.user.firstName && (<h3 style={{ maxWidth: '1320px'}} className='welcome-user'>Welcome, {props.user.firstName} &#127881;</h3>)}
      <Tabs design={props.selectedDesign.design} active={activeTab} onTabChange={setActiveTab}/>
      <TabContentComponent design={props.selectedDesign.design} activeTab={activeTab} />
    </Container>
    )
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  userState: state.users,
  plan: state.plans.detailPlan,
  planError: state.plans.error,
  api: state.apis.detailApi,
  formData: state.form.log_in_form,
  currentOrganization: state.organization.currentOrg,
  imageLayouts: state.linkPreview.imageLayouts,
  imageDesigns: state.linkPreview.imageDesigns,
  selectedDesign: state.linkPreview.selectedDesign,
});

const mapDispatchToProps = (dispatch) => ({
  getPlanByApiAndOrganization: (() => dispatch(getPlanByApiAndOrganization())),
  getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
  getImageLayouts: () => dispatch(getImageLayouts()),
  getOrganizationImageDesigns: () => dispatch(getOrganizationImageDesigns()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkPreview)
