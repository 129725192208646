import React, { useState} from 'react'
import {Card, CardBody, Col, Button} from 'reactstrap'
import {connect} from 'react-redux'
import { showStripeModal, showStripeFreeModal } from '../../../redux/actions/stripeModal'
import check from '../../../shared/img/check.svg'
import mark from '../../../shared/img/mark.svg'

const TierCard = (props) => {
    const [tier, setTier] = useState(props.tier || {})
    const { dashboardView } = props;

    const trialTierBorderStyle = {
        border: '1px solid #090A3AFF',
        flex: 'unset',
        height: 'unset',
    }

    const mapDescriptionToBullets = (tier) => {
        let description = tier.description || '';
        // Split by pipe separator
        let bullets = description.split('|');

        // Filter based on dashboard view
        if (dashboardView === 'MARKETER') {
            // If MARKETER, only show marketing bullets (starting with '-')
            bullets = bullets.filter(bullet => bullet.trim().startsWith('-'));
        } else {
            // If NOT MARKETER, filter out marketing bullets (starting with '-')
            bullets = bullets.filter(bullet => !bullet.trim().startsWith('-'));
        }

        return bullets.map((bullet) => {
            // Trim the bullet and remove '-' prefix if present
            let bulletText = bullet.trim();
            if (bulletText.startsWith('-')) {
                bulletText = bulletText.substring(1);
            }

            if(bulletText.startsWith('x')){
                const bulletPoint = bulletText.replace('x', '')
                return (
                    <tr key={bulletText}>
                        <td className='pricing-card__x'><img alt={'X-mark'} src={mark}/></td>
                        <td className="pricing-card__non_feature">{bulletPoint}</td>
                    </tr>
                )
            }
            return (
                <tr key={bulletText}>
                    <td className='pricing-card__check'><img alt={'checkmark'} src={check}/></td>
                    <td className="pricing-card__feature">{bulletText}</td>
                </tr>
            )
        })
    }

    const renderFreeTrialBubble = () => {
        if(props.tier.trialTierId && !props.plan.hasUsedFreeTrial) {
            return <div className='pricing-card__free-trial-bubble'>Free Trial</div>
        }
    }

    const renderButton = () => {
        if(props.tier.trialTierId && !props.plan.hasUsedFreeTrial && (props.currentTierId === tier.trialTierId || props.currentTierId === tier.id)) {
            return <Button id={tier.name} className="pricing-card__button darkGreyBackground" color="primary" disabled={true}
                           onClick={() => props.showStripeModal(tier)}>Free Trial</Button>
        } else if (props.tier.trialTierId && !props.plan.hasUsedFreeTrial) {
            return <Button id={tier.name} className="pricing-card__button" color="primary"
                           onClick={() => props.showStripeModal(tier)}>Free Trial</Button>
        } else if (props.currentTierId === tier.id || props.currentTierId === tier.trialTierId) {
            return <Button id={tier.name} className="pricing-card__button darkGreyBackground"
                           color="primary" disabled={true}>Current Plan</Button>
        }else if(props.tier.priceMonthly === 0) {
            return  <Button id={tier.name} className="pricing-card__button" color="primary"
                            onClick={() => props.showStripeFreeModal(tier)}>{props.currentTierId ? 'Switch Plans' : 'Sign Up'}</Button>
        }
        else {
            return  <Button id={tier.name} className="pricing-card__button" color="primary"
                            onClick={() => props.showStripeModal(tier)}>{props.currentTierId ? 'Switch Plans' : 'Sign Up'}</Button>
        }
    }
    return (
          <CardBody style={ tier.trialTierId && props.plan.hasUsedTrialTier ? trialTierBorderStyle : { flex: 'unset', height: 'unset', }} className="pricing-card__body">
              {renderFreeTrialBubble()}
              <div className='pricing-card__header'>
                  <h3 className="current-billing--info pricing-card__plan">{tier.name}</h3>
                  {tier.priceMonthly === 0 ? <p className="pricing-card__price">Free</p> : (
                    <p className="pricing-card__price">${tier.priceMonthly}<span> monthly</span></p>
                  )}
              </div>
              <table className={`pricing-card-details__table ${props.arrow}`}>
                  {mapDescriptionToBullets(tier)}
              </table>
              <div className='pricing-card__button_container'>
                  {renderButton()}
              </div>
          </CardBody>
    )
}

const mapStateToProps = (state) => ({
    plan: state.plans.detailPlan,
    dashboardView: state.preferences.dashboard.view
});

const mapDispatchToProps = (dispatch) => ({
    showStripeModal: (payload) => dispatch(showStripeModal(payload)),
    showStripeFreeModal: (payload) => dispatch(showStripeFreeModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TierCard)
